window.dataLayer = window.dataLayer || [];

function gtag() {
    dataLayer.push(arguments);
}

gtag('js', new Date());
gtag('config', 'UA-3168440-18');

export function gtagTrackEvent(event) {
    gtag('event', event, {
        event_category: 'interactions-login',
    });
}
