import { gtagTrackEvent } from './Analytics.js';

const passwordStrengthFeedback = [
    'zeer zwak',
    'zwak',
    'gemiddeld',
    'sterk',
    'zeer sterk',
];
(function() {
    document.addEventListener('DOMContentLoaded', function() {
        document.documentElement.classList.remove('no-js');
        const userInput = document.querySelector('[data-user-input]');
        const showPasswordButtons = document.querySelectorAll(
            '[data-show-password-button]'
        );
        const passwordStrengthInput = document.querySelector(
            '[data-password-strength-input]'
        );
        const passwordStrengthBar = document.querySelector(
            '[data-password-strength-bar]'
        );
        const passwordStrengthText = document.querySelector(
            '[data-password-strenght-text]'
        );
        const gtagClick = document.querySelector('[data-gtag-click-event]');

        if (userInput) userInput.focus();
        if (showPasswordButtons.length) {
            for (let i = 0; i < showPasswordButtons.length; i++) {
                showPasswordButtons[i].addEventListener('click', function() {
                    togglePassword(i);
                });
            }
        }
        if (passwordStrengthInput) {
            const passwordRegex = passwordStrengthInput.getAttribute(
                'data-val-regex-pattern'
            );
            passwordStrengthInput.addEventListener('keyup', function(e) {
                if (e.target.value === '') {
                    passwordStrengthText.innerText = getPasswordStrengthText();
                    passwordStrengthBar.setAttribute('data-value', 0);
                }
                passwordStrengthCheck(
                    e.target.value,
                    passwordStrengthBar,
                    passwordStrengthText,
                    passwordRegex
                );
            });
        }
        if (gtagClick) {
            gtagClick.addEventListener('click', function() {
                gtagTrackEvent(gtagClick.getAttribute('data-gtag-click-event'));
            });
        }
        preventMultipleSubmission();
    });

    function togglePassword(i) {
        const hideClass = 'button-show-password--hide';
        const password = document.querySelectorAll(
            '[data-show-password-input]'
        )[i];
        const icon = document.querySelectorAll('[data-show-password-button]')[
            i
        ];
        const showPasswordText = document
            .querySelectorAll('[data-show-password-button]')
            [i].getAttribute('data-show-password-text');
        const hidePasswordText = document
            .querySelectorAll('[data-show-password-button]')
            [i].getAttribute('data-hide-password-text');
        const titleText = icon.classList.contains(hideClass)
            ? showPasswordText
            : hidePasswordText;
        password.type = password.type === 'password' ? 'text' : 'password';
        icon.setAttribute('title', titleText);
        icon.classList.toggle(hideClass);
    }

    // prevent multiple submission in combination with unobtrusive validation
    function preventMultipleSubmission() {
        if (window.jQuery) {
            $('[data-form-submit-once]').on('submit', function() {
                const form = $(this);
                const gtagEvent = form.data('gtag-event');
                const buttons = form.find('[type="submit"]');
                const disabled = form.valid();
                buttons.each(function(btn) {
                    $(buttons[btn]).prop('disabled', disabled);
                });
                gtagTrackEvent(gtagEvent);
            });
        }
    }

    function passwordStrengthCheck(password, bar, text, passwordRegex) {
        if (!password || !bar || !text) {
            return false;
        }

        const passwordStrength = window.zxcvbn(password);
        const regx = new RegExp(passwordRegex);

        // avoid maximum score if it doesn't match password requirements
        const updatedScore =
            passwordStrength.score > 1 && !regx.test(password)
                ? passwordStrength.score - 1
                : passwordStrength.score;

        // provide feedback to the user, in the bar and text
        bar.setAttribute('data-value', updatedScore);
        text.innerText = getPasswordStrengthText(updatedScore);
    }

    function getPasswordStrengthText(score = 0) {
        return `Wachtwoordsterkte: ${passwordStrengthFeedback[score]}`;
    }
})();
